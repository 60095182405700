// CartPage.scss
$highlight-color: #A31332;

.cart-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .cart-header {
    margin-bottom: 20px;
    text-align: center;
  }

  .cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eaeaea;

    &:last-child {
      border-bottom: none;
    }
  }

  .cart-total {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    text-align: right;
  }
}

.btn-clear-cart {
  position: absolute;
  top: 10px;
  right: 10px;

  color: red;
  padding: 10px 15px;
  border-radius: 15px;
}

.checkout-button {
  display: block;
  width: 100%;
  padding: 15px;
  margin-top: 30px;
  background-color: $highlight-color;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: darken($highlight-color, 10%);
  }
}