.list-category {
  border-bottom: 1px solid #000000;
  border-top: 1px solid #000000;
  padding-bottom: 30px;

  &__body {
    padding-bottom: 20px;
    padding-top: 70px;
    .col-3 {
      max-width: 23%;
    }
    &__card {
      margin: 28px 17px;
    }
  }
}
