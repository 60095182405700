.custom-frames {
  margin: 112px 0;
  h5 {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    margin-bottom: 16px;
  }
  h3 {
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 24px;
    text-align: center;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    margin-bottom: 80px;
  }
  &__wrapper {
    .col-6 {
      padding: 40px;
      h3 {
        font-size: 40px;

        text-align: unset;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        text-align: unset;
      }
    }
    .col-3 {
      max-width: 20%;
    }
    &__card {
      margin: 15px;
      padding: 25px;
      border: 1px solid black;
      border-radius: 20px;
      .btn-colored {
        margin-right: 20px;
      }
      .btn-colored,
      .btn-white {
        padding: 13px 23px;
      }
      .btn-white {
        margin-bottom: 25px;
      }
      img {
        margin-bottom: 20px;
      }
      h3 {
        font-size: 24px;
        text-align: unset;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        text-align: unset;
        margin-bottom: 32px;
      }
      button {
        img {
          margin: 0;
          margin-left: 7px;
        }
      }
    }
  }
}
