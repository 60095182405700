.discover {
  margin: 112px 0;
  &__body {
    &__left {
      padding: 0;
      img {
        width: 100%;
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      background-color: #f1f1f1;
      justify-content: center;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 80px;

      span {
        font-weight: bold;
        font-size: 16px;
      }
      h3 {
        font-size: 48px;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 20px;
      }
      p {
        font-size: 18px;
        margin-bottom: 40px;
      }
      .btn-colored {
        margin-right: 30px;
        img {
          margin-left: 7px;
        }
      }
      .btn-colored,
      .btn-white {
        padding: 12px 24px;
      }
    }
  }
}
