.newsletter {
  padding: 112px 0;
  background-color: #f1f1f1;
  &__card {
    h3 {
      font-family: Kanit;
      font-size: 48px;
      font-style: normal;
    }
    p {
      font-size: 18px;
      font-style: normal;
      margin-bottom: 40px;
    }
    &__input {
      input {
        width: 45%;
        padding: 12px;
        border-radius: 4px;
        border: 1px solid #e2e2e2;
      }
      .btn-colored {
        margin-left: 16px;
      }
    }
    span {
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      display: block;
      margin-top: 16px;
    }
  }
}
