.products {
  background-color: #f1f1f1;
  padding: 80px 0;

  span {
    font-weight: bold;
  }

  h3 {
    margin-top: 16px;
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  p {
    font-size: 16px;
  }

  &__header {
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-colored {
      margin-right: 25px;
      font-size: 16px;
      padding: 12px 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__body {
    position: relative;
    height: 540px;

    .react-multi-carousel-list {
      overflow: visible;
      display: flex;
      justify-content: center;

      .react-multi-carousel-item {
        flex: 0 0 auto; // Makes the item flexible in terms of width

        // Adjust the card width based on the viewport width
        @media (min-width: 1625px) {
          // superLargeDesktop
          width: 20%; // Adjusts each item to take roughly 20% of the container width
        }

        @media (max-width: 1624px) and (min-width: 1025px) {
          // desktop
          width: 20%; // 5 items in a row
        }

        @media (max-width: 1024px) and (min-width: 465px) {
          // tablet
          width: 50%; // 2 items in a row
        }

        @media (max-width: 464px) {
          // mobile
          width: 100%; // 1 item takes the full width
        }
      }
    }

    .react-multi-carousel-dot-list {
      bottom: -80px;
      left: -20px;
      width: 95px;

      button {
        background-color: #d9d9d9;
        border: none;
      }

      .react-multi-carousel-dot--active {
        button {
          background: #a31332;
        }
      }
    }

    .react-multiple-carousel__arrow {
      bottom: -85px;
      background-color: transparent;
      border: 1px solid #860a25;
      outline: none;

      &:hover {
        background-color: #860a25;

        &::before {
          color: white;
        }
      }

      &--left {
        left: unset;
        right: calc(4% + 54px);
      }

      &::before {
        color: #860a25;
      }
    }

    &__card {
      padding: 15px;
      background-color: #e0e0e0;
      display: flex;
      flex-direction: column;

      img {
        width: 100%;
        height: auto;
      }

      &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 3px;
        }
      }

      .btn-colored {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}