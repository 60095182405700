.checkout-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
    h2 {
      text-align: center;
    }
  
    form {
      display: flex;
      flex-direction: column;
  
      input[type="text"],
      input[type="email"],
      input[type="tel"] {
        padding: 10px;
        margin: 10px 0;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
  
      button[type="submit"] {
        padding: 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  