.category-card__card {
  width: 416px;
  padding: 16px;

  background-color: #e0e0e0;
  img {
    width: 100%;
    height: 384px;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 3px;
      margin-top: 10px;
    }
  }
  .btn-colored {
    width: 100%;
    margin-top: 10px;
  }
}
