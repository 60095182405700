.footer {
  padding-top: 80px;
  padding-bottom: 65px;
  .border-bottom {
    border-bottom: 1px solid #020a1c !important;
  }
  .footer__card-bigger {
    h3 {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      margin: 24px 0;
    }
    p {
      font-size: 16px;
      font-style: normal;
      margin-bottom: 163px;
    }
  }
  .footer__card {
    h3 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 25px;
    }
    ul {
      list-style: none;
      li {
        margin-top: 16px;
        a {
          color: #a31332;
          font-weight: 400;
          font-size: 14px;
          font-family: "Roboto", sans-serif;
        }
      }
    }
    &__icon {
      display: flex;
      margin-top: 16px;
      align-items: center;

      a {
        color: black;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        display: block;
        margin-left: 10px;
        font-size: 14px;
      }
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 14px;
      margin-top: 32px;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      margin-top: 32px;
      li {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
        a {
          color: #a31332;
          text-decoration: underline;
          display: block;
          font-size: 14px;
          font-weight: 400;
          font-family: "Roboto", sans-serif;
        }
      }
    }
  }
}
