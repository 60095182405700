.hero {
  background-color: #f1f1f1;
  padding: 64px 0;
  border-bottom-right-radius: 300px;
  h3 {
    font-size: 56px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 140px;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 20px;
  }
  span {
    font-size: 16px;
  }
  &__desc {
    margin-bottom: 50px;
    margin-top: 35px;
    span {
      display: inline-block;
      margin: 8px;
      margin-left: 0;

      img {
        margin-right: 10px;
      }
    }
  }
  &__btns {
    button {
      width: 220px;
      font-size: 16px;
      padding: 10px 0;
    }
    .btn-colored {
      margin-right: 15px;
    }
  }
  &__img {
    width: 100%;
  }
}
